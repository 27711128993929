import { runGQLQuery } from "@hoppscotch/common/helpers/backend/GQLClient"
import {
  BannerStatus,
  BannerType,
  LastActiveBannerDocument,
} from "../api/generated/graphql"
import {
  BANNER_PRIORITY_HIGH,
  BannerContent,
  BannerService,
} from "@hoppscotch/common/services/banner.service"
import { Service } from "dioc"
import * as E from "fp-ts/Either"
import { ref } from "vue"

// Get the banner type based on the backend banner type
const getCustomBannerType = (type: BannerType) => {
  switch (type) {
    case BannerType.Info:
      return "info"
    case BannerType.Warning:
      return "warning"
    case BannerType.Error:
      return "error"
    default:
      return "info"
  }
}

/**
 *
 * Service for managing custom banners
 */
export class CustomBannerService extends Service {
  /**
   * The ID of the custom banner service.
   */
  public static readonly ID = "CUSTOM_BANNER_SERVICE"

  private readonly banner = this.bind(BannerService)

  // The custom banner to be displayed
  private customBanner = ref<BannerContent | null>(null)

  onServiceInit() {
    this.getCustomBannerInfo()
  }

  /**
   * Retrieves last active banner information from the backend
   * and displays the banner if it is active
   */
  getCustomBannerInfo = async () => {
    const customBanner = await runGQLQuery({
      query: LastActiveBannerDocument,
      variables: {},
    })

    if (customBanner && E.isRight(customBanner)) {
      const customBannerData = customBanner.right?.lastActiveBanner
      if (!customBannerData) return

      // If the banner status is inactive, don't show it
      if (customBannerData.status === BannerStatus.Inactive) return

      this.customBanner.value = {
        type: getCustomBannerType(customBannerData.type),
        text: () => customBannerData?.message || "",
        alternateText: (t) => t("action.expand_screen_width"),
        score: BANNER_PRIORITY_HIGH,
        dismissible: true,
      }

      this.banner.showBanner(this.customBanner.value)
    }
  }
}
